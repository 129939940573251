const orgMap = {
  oceanstreet: {
    code: "org_b14a173a68d",
    name: "Ocean Street",
    key: "oceanstreet",
    patients: [
      {
        name: "Suzy",
        type: "Canine",
        age: "5 months",
        weight: "6 kg",
        image: "corgi",
        breed: "Corgi",
        contact: {
          name: "Katherine Franklin",
          phone: "51 9873458",
          email: "kathe@gmail.com",
        },
      },
    ],
  },
  pawsitive: {
    code: "org_6214e1417f5",
    name: "Pawsitive",
    key: "pawsitive",
    patients: [
      {
        name: "Copper",
        type: "Canine",
        age: "18 months",
        weight: "16 kg",
        image: "pug",
        breed: "Pug",
        contact: {
          name: "Johnny Cash",
          phone: "555 1234 542",
          email: "jcash@gmail.com",
        },
      },
    ],
  },
  allpaws: {
    code: "org_b1e56150ab1",
    name: "All Paws",
    key: "allpaws",
    patients: [
      {
        name: "Chase",
        type: "Canine",
        age: "2 months",
        weight: "100 kg",
        image: "labrador",
        breed: "Labrador",
        contact: {
          name: "Jimmy Pop Ali",
          phone: "555 9876 432",
          email: "popper@gmail.com",
        },
      },
    ],
  },
  default: {
    code: "org_79b4331760b",
    name: "Tailsforce",
    key: "tailsforce",
    patients: [
      {
        name: "Tails",
        type: "Canine",
        age: "1 month",
        weight: "1 kg",
        image: "labrador",
        breed: "Labrador",
        contact: {
          name: "Oli Wolff",
          phone: "555 9876 432",
          email: "oli@kinde.com",
        },
      },
    ],
  },
};

export { orgMap };
