import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useEffect, useState } from "react";
import { orgMap } from "../data/orgCodes";

export default function LoggedOut() {
  const { login } = useKindeAuth();
  const [orgCode, setOrgCode] = useState();

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const hostNameSplit = window.location.hostname.split(".")[0];
    const subdomain =
      hostNameSplit &&
      !["localhost", "tailsforce", "www"].includes(hostNameSplit)
        ? hostNameSplit
        : undefined;
    if (params || subdomain) {
      console.log({ subdomain });
      const orgKey = subdomain || params.get("org");
      setOrgCode(orgMap?.[orgKey]?.code);
    }
  }, []);

  return (
    <div>
      <header>
        <nav className="nav container">
          <img
            src={`/images/logos/tailsforce.png`}
            className="c-logged-out-logo"
            alt={`Tailsforce logo`}
          />
          <div>
            <button
              className="btn btn-dark"
              onClick={() =>
                login({
                  org_code: orgCode,
                })
              }
            >
              Sign in
            </button>
          </div>
        </nav>
      </header>

      <main>
        <div className="container">
          <div className="card hero">
            <p className="text-display-1 hero-title">Vet practice management</p>
            <p className="text-body-1 hero-tagline">
              So you can do what matters
            </p>

            <a
              href="https://kinde.com/docs/developer-tools/react-sdk"
              target="_blank"
              rel="noreferrer"
              className="btn btn-light btn-big"
            >
              Request early access
            </a>
          </div>
        </div>
      </main>

      <footer className="footer">
        <div className="container">
          <strong className="text-heading-2">Tailsforce</strong>
          <p className="footer-tagline text-body-3">
            Visit our{" "}
            <a className="link" href="https://kinde.com/docs">
              help center
            </a>
          </p>

          <small className="text-subtle">
            © 2022 Tailsforce, Inc. All rights reserved
          </small>
        </div>
      </footer>
    </div>
  );
}
